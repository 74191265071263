import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Footer from "../components/footer"
import Header from "../components/header"
import "../styles/404.scss"

const NotFound = () => {
  return (
    <>
      <SEO title="Error 404" description="Page not found" />
      <Header />
      <main>
        <section>
          <div className="container">
            <div className="content">
              <h1>404</h1>
              <h2>NOT FOUND</h2>
              <h3>The resource requested could not be found on this server</h3>
              <div className="error-button">
                <Link to="/">
                  <button>Show me the way</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer id="contact" />
    </>
  )
}

export default NotFound
